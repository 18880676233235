import { useGetBillingCodeResourceQuery, useGetInvestmentStyleResourceQuery, useGetToAResourceQuery, useGetVendorResourceQuery } from "../store/api";

export default function useResources() {
    const vendorsRespose = useGetVendorResourceQuery()
    const toaResponse = useGetToAResourceQuery()
    const investmentStyleResponse = useGetInvestmentStyleResourceQuery()
    const billingCodeResponse = useGetBillingCodeResourceQuery()

    const responses = {
        vendors: vendorsRespose,
        ToA: toaResponse,
        investmentStyles: investmentStyleResponse,
        billingCodes: billingCodeResponse
    }

    const allReady = Object
        .values(responses)
        .every(response => response.isSuccess && !response.isFetching)

    return {
        allReady,
        data: Object
            .entries(responses)
            .reduce((data, responseEntry) => {
                const [ name, response ] = responseEntry

                return {
                    ...data,
                    [name]: response.data
                }
            }, {})
    }
}