export const REPORTS_BROWSER = {
    pathname: '/reports',
    title: 'Reports Browser'
}

export const PROFESSIONALS_SEARCH_PAGE = {
    pathname: '/search/professionals',
    title: 'Professionals'
}

export const STORYLINE_PLANS_SEARCH = {
    pathname: '/search/storyline_plans',
    title: 'StoryLine Plans'
}

export default [
    REPORTS_BROWSER,
    PROFESSIONALS_SEARCH_PAGE,
    STORYLINE_PLANS_SEARCH
]