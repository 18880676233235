import { useState } from "react";
import FormInput from "../components/FormInput";

export default function useForm({ formFields }) {
    const [ form, setForm ] = useState(formFields.reduce(parseFormFields, {}))

    const getInputProps = name => ({
        value: form[name],
        checked: form[name],
        onChange: e => {
            const { value, checked, type } = e.target
            const newFormValue = type === 'checkbox' ? checked : value

            setForm({
                ...form,
                [name]: newFormValue
            })
        }
    })

    const getFormField = name => formFields.find(ff => ff.name === name)

    return {
        form,
        setForm,
        getInputProps,
        getFormField,

    }
}

function parseFormFields(form, formField) {
    const { name, defaultValue = '' } = formField

    return {
        ...form,
        [name]: defaultValue
    }
}