import { Button, Form, InputGroup, Spinner, Stack } from "react-bootstrap";
import { useGetLMDQuery, useGetVendorsQuery, useLazyGetAppReportQuery, useLazyGetAppReportsQuery } from "../store/api";
import moment from 'moment'
import { curry } from 'ramda'
import prettyBytes from 'pretty-bytes'
import { useEffect, useState } from "react";
import TableSearchLayout from "../components/TableSearchLayout";
import { usePagination } from "../hooks/pagination";
import Pagination from "../components/Pagination";
import useFormInput from "../hooks/formInput";
import FormInput, { defaultOptionRenderer } from "../components/FormInput";
import { DynamicTable } from "../components/DynamicTable";
import useForm from "../hooks/form";

export default function ReportsBrowser() {
    const getVendorsResponse = useGetVendorsQuery()
    const getLMDResponse = useGetLMDQuery()
    const [ getReports, getReportsResponse ] = useLazyGetAppReportsQuery()
    const [ getAppReport, getAppReportResponse ] = useLazyGetAppReportQuery()

    const [ lmd, setLMD ] = useState(null)

    const { form, setForm, getInputProps, getFormField } = useForm({ formFields })

    const pagination = usePagination({
        total: getReportsResponse.data?.reportFiles?.length,
    })

    const {
        page,
        setPage,
        itemsPerPage,
        setItemsPerPage,
        itemsPerPageOptions,
        numberOfPages,
        handleNextPage,
        handlePrevPage,
        prevDisabled,
        nextDisabled,
        startIndex,
        endIndex,
        total
    } = pagination

    const downloadHandler = Key => getAppReport(Key)

    useEffect(() => {
        if (getAppReportResponse.currentData)
            window.open(getAppReportResponse.currentData.presignedUrl)
    }, [ getAppReportResponse ])

    const tableRowRenderer = curry(ReportBrowserTableRow)(getVendorsResponse, downloadHandler)

    const handleGetReports = e => {
        e.preventDefault()

        triggerGetReports()
    }

    const triggerGetReports = () => {
        getReports(generateQueryString(form))
    }

    const formLoading = getReportsResponse.isLoading || getReportsResponse.isFetching

    useEffect(() => {
        if (!lmd && getLMDResponse.data)
            setLMD(getLMDResponse.data.lmd)
    }, [ getLMDResponse ])

    useEffect(() => {
        if (lmd) {
            setForm({
                ...form,
                from: lmd,
                to: lmd
            })
        }
    }, [ lmd ])

    useEffect(() => {
        if (getReportsResponse.isUninitialized && form.from && form.to)
            triggerGetReports()
    }, [ getReportsResponse, form.from, form.to ])

    if (getVendorsResponse.isLoading || getLMDResponse.isLoading)
        return <Spinner />

    const searchForm = (
        <Form onSubmit={ handleGetReports }>
            <Stack gap={ 2 }>
                <Button type="submit" style={ { position: 'sticky', top: 0 } }>Search</Button>
                <FormInput
                    inputProps={ getInputProps('fileName') }
                    formField={ getFormField('fileName') }
                />
                <FormInput
                    inputProps={ getInputProps('vendorIds') }
                    formField={ getFormField('vendorIds') }
                    selectOptions={ [
                        { value: '', label: 'Any' },
                        ...getVendorsResponse
                            .data?.map(( { vendor_id, vendor_name }) => ({ value: vendor_id, label: vendor_name  }))
                        ]
                    }
                    selectOptionsRenderer={ defaultOptionRenderer }
                />
                <FormInput
                    inputProps={ getInputProps('extensions') }
                    formField={ getFormField('extensions') }
                    selectOptions={ (
                        <>
                            <option value="">Any</option>
                            <option value="csv">CSV</option>
                            <option value="html">HTML</option>
                            <option value="pdf">PDF</option>
                            <option value="XLS">XLS</option>
                        </>
                    ) }
                />
                <FormInput
                    inputProps={ getInputProps('from') }
                    formField={ getFormField('from') }
                />
                <FormInput
                    inputProps={ getInputProps('to') }
                    formField={ getFormField('to') }
                />
            </Stack>
        </Form>
    )

    const dataPresentation = (
        <DynamicTable
            dataResponse={ getReportsResponse }
            isLoading={ formLoading }
            data={ getReportsResponse.data?.reportFiles.slice(startIndex, endIndex) }
            startIndex={ startIndex }
            endIndex={ endIndex }
            thead={(
                <thead>
                    <tr>
                        <th>Vendor</th>
                        <th>Date</th>
                        <th>File Name</th>
                        <th>Size</th>
                        <th>Last Modified</th>
                        <th>Action</th>
                    </tr>
                </thead>
            )}
            tableRowRenderer={ tableRowRenderer }
        />
    )

    const paginationElement = <Pagination
        page={ page }
        numberOfPages={ numberOfPages }
        handleNextPage={ handleNextPage }
        handlePrevPage={ handlePrevPage }
        nextDisabled={ nextDisabled }
        prevDisabled={ prevDisabled }
        setPage={ setPage }
        itemsPerPage={ itemsPerPage }
        setItemsPerPage={ setItemsPerPage }
        itemsPerPageOptions={ itemsPerPageOptions }
        total={ total }
    />

    return <TableSearchLayout
        searchForm={ searchForm }
        dataPresentation={ dataPresentation }
        pagination={ paginationElement }
        title="Reports Browser"
    />
}

function ReportBrowserTableRow(getVendorsResponse, downloadHandler, reportInfo) {
    const { Key } = reportInfo

    const [ vendorId, date ] = Key.split('/')

    return (
        <tr key={ Key }>
            <td>
                { getVendorsResponse.data?.find(v => v.vendor_id == vendorId).vendor_name }
            </td>
            <td>
                { moment(date).format('MM/DD/YYYY') }
            </td>
            <td>
                { reportInfo.Key.split('/').slice(2) }
            </td>
            <td>
                { prettyBytes(reportInfo.Size, { maximumFractionDigits: 0 }) }
            </td>
            <td>
                { moment(reportInfo.LastModified).format('MM/DD/YYYY hh:mm:ss A') }
            </td>
            <td style={ { textAlign: 'end' } }>
                <Button size="sm" onClick={ () => downloadHandler(Key) }>
                    Download
                </Button>
            </td>
        </tr>
    )
}

function generateQueryString(query) {
    let queryObj = {}

    if (query.from && query.to && query.from === query.to)
        queryObj.exact = `exact=${query.from}`

    if (query.from && !queryObj.exact)
        queryObj.from = `from=${query.from}`

    if (query.to && !queryObj.exact)
        queryObj.to = `to=${query.to}`

    if (query.extensions)
        queryObj.extensions = `extensions=${query.extensions}`

    if (query.vendorIds)
        queryObj.vendorIds = `vendorIds=${query.vendorIds}`

    if (query.fileName)
        queryObj.fileName = `fileName=${query.fileName}`

    console.log({ query, queryObj })

    return Object.values(queryObj).join('&')
}

const formFields = [
    {
        name: 'fileName',
        label: 'File name'
    },
    {
        name: 'vendorIds',
        label: 'Vendor',
        type: 'select',
        defaultValue: ''
    },
    {
        name: 'extensions',
        label: 'File type',
        type: 'select',
        defaultValue: ''
    },
    {
        name: 'from',
        label: 'From',
        type: 'date'
    },
    {
        name: 'to',
        label: 'To',
        type: 'date'
    },
]