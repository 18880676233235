import { useEffect, useState } from "react"

const itemsPerPageOptions = [ 10, 20, 30, 50, 100 ]

export function usePagination({
    total = null
}) {
    const [ page, setPage ] = useState(1)
    const [ itemsPerPage, setItemsPerPage ] = useState(10)
    const [ numberOfPages, setNumberOfPages ] = useState(1)

    const handleNextPage = () => setPage(page + 1)
    const handlePrevPage = () => setPage(page - 1)

    const nextDisabled = (page === numberOfPages) || numberOfPages === 0
    const prevDisabled = page === 1

    useEffect(() => {
        setPage(1)
        // setNumberOfPages(Math.ceil((total === null ? data.length : total) / itemsPerPage))
        setNumberOfPages(Math.ceil(total / itemsPerPage))
    }, [ total, itemsPerPage ])
    
    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    return {
        page,
        setPage,
        itemsPerPage,
        setItemsPerPage,
        itemsPerPageOptions,
        numberOfPages,
        nextDisabled,
        prevDisabled,
        handleNextPage,
        handlePrevPage,
        startIndex,
        endIndex,
        total
    }
}