export const mapSSSResponseEntry = data => data
    .reduce((responseEntry, prop) => {
        const { name, value } = prop

        return {
            ...responseEntry,
            [name]: value
        }
    }, {})

export const defaultBooleanSelectOptions = [
    { value: '', label: 'Any' },
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' }
]

export const excludeEmptyFields = form => Object
    .entries(form)
    .reduce((form, entry) => {
        const [ name, value ] = entry

        if (value === '')
            return form
        else
            return {
            ...form,
            [name]: value
        }
    }, {})
    
export const generateSSSQuery = ({
    searchType,
    itemsPerPage,
    page,
    order,
    form,
    headers,
    searchSortProp
}) => {
    // get searchSort
    const header = headers.find(h => h.name === searchSortProp)

    // page number not needed for count
    const query = {
        ...excludeEmptyFields(form)
    }

    return {
        searchType,
        searchPage: page,
        searchRows: itemsPerPage,
        searchSort: `${order === 'ASC' ? '' : '10'}${header.id}`,
        ...query
    }
}

export const vendors = [
    {
        "vendor_id": 175,
        "vendor_name": "Ameritas"
    },
    {
        "vendor_id": 158,
        "vendor_name": "Ascensus"
    },
    {
        "vendor_id": 177,
        "vendor_name": "BCG"
    },
    {
        "vendor_id": 186,
        "vendor_name": "CUNA"
    },
    {
        "vendor_id": 124,
        "vendor_name": "EPIC"
    },
    {
        "vendor_id": 187,
        "vendor_name": "Equitable"
    },
    {
        "vendor_id": 102,
        "vendor_name": "Guardian"
    },
    {
        "vendor_id": 178,
        "vendor_name": "July"
    },
    {
        "vendor_id": 101,
        "vendor_name": "Lincoln"
    },
    {
        "vendor_id": 119,
        "vendor_name": "Mutual of Omaha"
    },
    {
        "vendor_id": 155,
        "vendor_name": "Nationwide"
    },
    {
        "vendor_id": 185,
        "vendor_name": "Securian"
    },
    {
        "vendor_id": 191,
        "vendor_name": "Standard"
    },
    {
        "vendor_id": 181,
        "vendor_name": "TRPC"
    }
]

export const vendorOptions = vendors.map(({ vendor_id, vendor_name }) => ({
    value: vendor_id.toString(),
    label: vendor_name
}))

export const mapVendorOptionToSelectOption = ({ value, id }) => ({
    value: id.toString(),
    label: value
})

export const mapToAOptionToSelectOption = ({ display_value, toa_id }) => ({
    value: toa_id.toString(),
    label: display_value
})