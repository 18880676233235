import { Alert, Card, Spinner, Table } from "react-bootstrap"

export function DynamicTable({
    isLoading,
    data = [],
    thead,
    noDataMessage = 'No results to display',
    tableRowRenderer
}) {
    if (isLoading)
        return <Spinner variant="primary" />

    return (
        <>
            {
                data.length ?
                <Table variant="striped" responsive className="mb-0" hover>
                    { thead }
                    <tbody>
                        { data.map(tableRowRenderer) }
                    </tbody>
                </Table>
                : (
                    <Card.Body><Alert variant="warning">
                        { noDataMessage }
                    </Alert></Card.Body>
                )
            }
        </>
    )
}