import { Form, Stack } from "react-bootstrap";

export default function FormInput ({
    inputProps,
    formField,
    selectOptions = null,
    selectOptionsRenderer = null
}) {
    const {
        label,
        name,
        type = 'text'
    } = formField

    let formControl

    if (type === 'select' && selectOptions)
        formControl = (
            <Form.Select { ...inputProps }>
                { selectOptionsRenderer ? selectOptions.map(selectOptionsRenderer) : selectOptions }
            </Form.Select>
        )
    else if (type === 'switch')
        return (
            <Form.Group>
                <Stack direction="horizontal">
                    <Form.Label htmlFor={ name }>
                        { label }
                    </Form.Label>
                    <div className="ms-auto"></div>
                    <Form.Check
                        id={ name }
                        type={ type }
                        { ...inputProps }
                    />
                </Stack>
            </Form.Group>
        )
    else
        formControl = (
            <Form.Control
                type={ type }
                { ...inputProps }
            />
        )        

    const inputLabel = <Form.Text>{ label }</Form.Text>

    return (
        <Form.Group>
            { inputLabel }
            { formControl }
        </Form.Group>
    )
}

export const defaultOptionRenderer = option => (
    <option key={ option.value } value={ option.value }>
        { option.label }
    </option>
)