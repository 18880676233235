import { Button, Spinner, Stack } from "react-bootstrap"
import useForm from "../hooks/form"
import { mapToAOptionToSelectOption, mapVendorOptionToSelectOption, vendorOptions } from "../util/util"
import FormInput, { defaultOptionRenderer } from "./FormInput"
import useResources from "../hooks/resources"

export default function AddSLPlan() {
    const resources = useResources()
    const { form, getFormField, getInputProps, setForm } = useForm({ formFields })

    if (!resources.allReady)
        return <Spinner variant="primary" />

    return (
        <form>
            <Stack gap={ 2 }>
                {
                    formFields.map(({ name, selectOptions }) => {
                        let inputSelectOptions

                        if (name === 'vendor_id')
                            inputSelectOptions = [
                                { value: '', label: '' },
                                ...resources
                                    .data.vendors
                                    .map(mapVendorOptionToSelectOption)
                                ]

                        if (name === 'vendor_id')
                            inputSelectOptions = [
                                { value: '', label: '' },
                                ...resources
                                    .data.ToA
                                    .map(mapToAOptionToSelectOption)
                                ]

                        if (name === 'billing_code')
                            inputSelectOptions = [
                                { value: '', label: '' },
                                ...resources
                                    .data.billingCodes
                                    .map(mapVendorOptionToSelectOption)
                                ]

                        return (
                            <FormInput
                                key={ name }
                                inputProps={ getInputProps(name) }
                                formField={ getFormField(name) }
                                selectOptions={ inputSelectOptions || selectOptions }
                                selectOptionsRenderer={ defaultOptionRenderer }
                            />
                        )
                    })
                }
            </Stack>
            <div className="mt-4 d-grid">
                <Button type="submit" variant="success">
                    Add
                </Button>
            </div>
        </form>
    )
}

const formFields = [
    {
        name: 'company_name',
        label: 'Company Name'
    },
    {
        name: 'vendor_id',
        label: 'Relationship',
        type: 'select'
    },
    {
        name: 'toa_id',
        label: 'ToA ID - update to ToA - todo',
        type: 'select',
        selectOptions: vendorOptions
    },
    {
        name: 'ned_plan_id',
        label: 'NED Plan ID'
    },
    {
        name: 'billing_code',
        label: 'Billing Code (ICU)',
        type: 'select'
    },
    {
        name: 'ein',
        label: 'EIN'
    },
    {
        name: 'smartplan_vwise',
        label: 'iJoin / GoalWise',
        type: 'switch',
        defaultValue: false
    },
    {
        name: 'startup',
        label: 'Startup',
        type: 'switch',
        defaultValue: false
    },
    {
        name: 'pep',
        label: 'PEP/MEP/PEO',
        type: 'switch',
        defaultValue: false
    },
    {
        name: 'enable_income',
        label: 'Income',
        type: 'switch',
        defaultValue: false
    }
]