import { useState } from "react";

export default function useWindowWidth() {
    const [ width, setWidth ] = useState(window.innerWidth)

    const handler = () => {
        setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handler)

    return width
}