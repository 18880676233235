import { useState } from "react";

export default function useSearchSorting({
    searchProp = null,
    searchOrder = 'ASC'
}) {
    const [ prop, setProp ] =  useState(searchProp)
    const [ order, setOrder ] = useState(searchOrder)

    const toggleOrder = () => setOrder(order === 'ASC' ? 'DESC' : 'ASC')

    return {
        prop,
        setProp,
        order,
        setOrder,
        toggleOrder
    }
}