import { Button, Form, Offcanvas, Spinner, Stack, Tab } from "react-bootstrap"
import useForm from "../hooks/form"
import { usePagination } from "../hooks/pagination"
import { defaultBooleanSelectOptions, excludeEmptyFields, generateSSSQuery, mapSSSResponseEntry, mapToAOptionToSelectOption, mapVendorOptionToSelectOption, vendorOptions } from "../util/util"
import FormInput, { defaultOptionRenderer } from "../components/FormInput"
import { DynamicTable } from "../components/DynamicTable"
import TableHeaders from "../components/TableHeaders"
import TableSearchLayout from "../components/TableSearchLayout"
import useSearchSorting from "../hooks/searchSorting"
import Pagination from "../components/Pagination"
import { useEffect, useState } from "react"
import { useGetStoryLinePlansQuery, useGetToAResourceQuery, useGetVendorResourceQuery } from "../store/api"
import { Plus, PlusCircle } from "react-bootstrap-icons"
import AddSLPlan from "../components/AddSLPlan"
import useResources from "../hooks/resources"

export default function StoryLinePlans() {
    const [ showAddSLPlan, setShowAddSLPlan ] = useState(false)
    const toggleShowAddSLPlan = () => setShowAddSLPlan(!showAddSLPlan)
    const { form, getInputProps, getFormField } = useForm({ formFields })
    const [ itemsTotal, setItemsTotal ] = useState(0)

    const resources = useResources()
    const loadingResources = !resources.allReady

    console.log({ form })

    const pagination = usePagination({
        total: itemsTotal
    })

    const {
        page,
        setPage,
        itemsPerPage,
        setItemsPerPage,
        itemsPerPageOptions,
        numberOfPages,
        handleNextPage,
        handlePrevPage,
        prevDisabled,
        nextDisabled,
        total
    } = pagination

    const searchSorting = useSearchSorting({
        searchProp: 'relationship',
        searchOrder: 'ASC'
    })
    
    const { order } = searchSorting
    
    const paginationElement = <Pagination
        page={ page }
        setPage={ setPage }
        itemsPerPage={ itemsPerPage }
        setItemsPerPage={ setItemsPerPage }
        itemsPerPageOptions={ itemsPerPageOptions }
        numberOfPages={ numberOfPages }
        handleNextPage={ handleNextPage }
        handlePrevPage={ handlePrevPage }
        nextDisabled={ nextDisabled }
        prevDisabled={ prevDisabled }
        total={ total }
    />

    const setQueries = e => {
        if (e)
            e.preventDefault()

        setItemsQuery(generateSSSQuery({
            searchType: 'RetStoryLine',
            itemsPerPage,
            page,
            order,
            form,
            headers,
            searchSortProp: searchSorting.prop
        }))

        setItemsCountQuery(generateSSSQuery({
            searchType: 'RetStoryLineCount',
            itemsPerPage,
            page,
            order,
            form,
            headers,
            searchSortProp: searchSorting.prop
        }))
    }

    const [ itemsQuery, setItemsQuery ] = useState(generateSSSQuery({
        searchType: 'RetStoryLine',
        itemsPerPage,
        page,
        order,
        form,
        headers,
        searchSortProp: searchSorting.prop
    }))

    const [ itemsCountQuery, setItemsCountQuery ] = useState(generateSSSQuery({
        searchType: 'RetStoryLineCount',
        itemsPerPage,
        page,
        order,
        form,
        headers,
        searchSortProp: searchSorting.prop
    }))

    console.log({ itemsQuery })

    const plansResponse = useGetStoryLinePlansQuery(itemsQuery)
    const plansCountResponse = useGetStoryLinePlansQuery(itemsCountQuery)

    useEffect(() => {
        if (plansCountResponse.data?.count !== itemsTotal)
            setItemsTotal(plansCountResponse.data?.count)
    }, [ plansCountResponse.data?.count ])

    useEffect(setQueries, [ itemsPerPage, page, order, searchSorting.prop ])

    console.log({ plansResponse, plansCountResponse })
    
    const searchForm = (
        loadingResources ?
        <Spinner variant="primary" />
        :
        <Form onSubmit={ setQueries }>
            <Stack gap={ 2 }>
                <Button type="submit" style={ { position: 'sticky', top: 0 } }>Search</Button>
                {
                    formFields
                        .map(({ name, selectOptions }) => {
                            let inputSelectOptions

                            if (name === 'vendor_id')
                                inputSelectOptions = [
                                    { value: '', label: 'Any' },
                                    ...resources.data.vendors
                                        .map(mapVendorOptionToSelectOption)
                                ]
                            else if (name === 'toa_id')
                                inputSelectOptions = [
                                    { value: '', label: 'Any' },
                                    ...resources.data.ToA
                                        .map(mapToAOptionToSelectOption)
                                ]
                            else if (name === 'investment_style')
                                inputSelectOptions = [
                                    { value: '', label: 'Any' },
                                    ...resources.data.investmentStyles
                                        .map(mapVendorOptionToSelectOption)
                                ]
                            else if (name === 'billing_code')
                                inputSelectOptions = [
                                    { value: '', label: 'Any' },
                                    ...resources.data.billingCodes
                                        .map(mapVendorOptionToSelectOption)
                                ]

                            return (
                                <FormInput
                                    key={ name }
                                    inputProps={ getInputProps(name) }
                                    formField={ getFormField(name) }
                                    selectOptions={ inputSelectOptions || selectOptions }
                                    selectOptionsRenderer={ defaultOptionRenderer }
                                />
                            )
                        })
                }
            </Stack>
        </Form>
    )

    const TableRowRenderer = item => {
        // return <tr>test</tr>
        const [ showDetails, setShowDetails ] = useState(false)
        const toggleShow = () => setShowDetails(!showDetails)

        const mappedItem = mapSSSResponseEntry(Object.values(item))

        console.log({ item, mappedItem })

        return <tr>
            <td></td>
            <td>
                { mappedItem.vendor_name }
            </td>
            <td>
                { mappedItem.company_name }
            </td>
            <td>
                { mappedItem.enrollment_code }
            </td>
            <td>
                { mappedItem.questionnaire_status_name }
            </td>
        </tr>
    }
    
    const dataPresentation = (
        <DynamicTable
            isLoading={ plansResponse.isLoading || plansResponse.isFetching || plansResponse.isUninitialized }
            data={ plansResponse.data }
            thead={(
                <TableHeaders
                    headers={ headers }
                    searchSorting={ searchSorting }
                />
            )}
            tableRowRenderer={ TableRowRenderer }
        />
    )

    const additionalOptions = (
        <Stack gap={ 2 } direction="horizontal">
            <div className="ms-auto"></div>
            <Button variant="outline-primary" onClick={ toggleShowAddSLPlan }>
                <Plus size={ 24 } className="me-1" />
                Add StoryLine Plan
            </Button>
        </Stack>
    )
    
    return (
        <>
            <Offcanvas placement="end" show={ showAddSLPlan } onHide={ toggleShowAddSLPlan }>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        Add StoryLine Plan
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <AddSLPlan />
                </Offcanvas.Body>
            </Offcanvas>
            <TableSearchLayout
                searchForm={ searchForm }
                dataPresentation={ dataPresentation }
                pagination={ paginationElement }
                title="StoryLine Plans"
                additionalOptions={ additionalOptions }
            />
        </>
    )
}

const headers = [
    { id: 0, name: '', label: null, sortable: false },
    { id: 1, name: 'relationship', label: 'Relationship' },
    { id: 2, name: 'company_name', label: 'Company Name' },
    { id: 3, name: 'enrollment_code', label: 'Enrollment Code' },
    { id: 4, name: 'questionnaire_status', label: 'Questionnaire Status' },
    { id: 4, name: '', label: null, sortable: false }
]

const formFields = [
    {
        name: 'vendor_id',
        label: 'Relationship',
        type: 'select',
        selectOptions: vendorOptions
    },
    {
        name: 'active',
        label: 'Active',
        type: 'select',
        selectOptions: defaultBooleanSelectOptions
    },
    {
        name: 'toa_id',
        label: 'ToA ID',
        type: 'select'
    },
    {
        name: 'smartplan_vwise',
        label: 'iJoin / GoalWise',
        type: 'select',
        selectOptions: defaultBooleanSelectOptions
    },
    {
        name: 'billing_code',
        label: 'Billing Code (ICU)',
        type: 'select'
    },
    {
        name: 'fee_waiver',
        label: 'Fee Waiver',
        type: 'select',
        selectOptions: defaultBooleanSelectOptions
    },
    {
        name: 'existing_plan',
        label: 'New/Existing (at RK)',
        type: 'select',
        selectOptions: defaultBooleanSelectOptions
    },
    {
        name: 'startup',
        label: 'Startup',
        type: 'select',
        selectOptions: defaultBooleanSelectOptions
    },
    {
        name: 'pep',
        label: 'PEP/MEP/PEO',
        type: 'select',
        selectOptions: defaultBooleanSelectOptions
    },
    {
        name: 'investment_style',
        label: 'Investment Style',
        type: 'select'
    },
    {
        name: 'dqdia',
        label: 'dQDIA',
        type: 'select',
        selectOptions: defaultBooleanSelectOptions
    },
    {
        name: 'enable_income',
        label: 'Income Enabled',
        type: 'select',
        selectOptions: defaultBooleanSelectOptions
    },
    {
        name: 'company_name',
        label: 'Company Name'
    },
    {
        name: 'ned_plan_id',
        label: 'NED Plan ID'
    },
    {
        name: 'master_enrollment_code',
        label: 'Master Enrollment Code'
    },
    {
        name: 'enrollment_code',
        label: 'Enrollment Code'
    },
    {
        name: 'ein',
        label: 'EIN'
    },
    {
        name: 'dynamic_age',
        label: 'Dynamic Age'
    },
    {
        name: 'plan_code',
        label: 'Plan Code'
    },
    {
        name: 'id',
        label: 'SL Plan ID'
    }
]