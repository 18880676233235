import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import useSearchSorting from "../hooks/searchSorting";
import { Stack } from "react-bootstrap";

export default function TableHeaders({
    headers,
    searchSorting
}) {
    return (
        <thead>
            <tr>
                {
                    headers.map(header => {
                        const { id, name, label, sortable = true } = header
                        const { prop, order, setProp, toggleOrder } = searchSorting

                        const clickHandler = sortable ? () => {
                            setProp(name)

                            if (prop === name)
                                toggleOrder()
                        } : null

                        const orderIndicator = name === prop ? (
                            order === 'ASC' ? <ChevronUp /> : <ChevronDown />
                        ) : null

                        return (
                            <th key={ id } onClick={ clickHandler } className={ clickHandler ? 'clickable' : '' }>
                                <Stack direction="horizontal">
                                    { label }
                                    <div className="ms-auto">
                                        { orderIndicator }
                                    </div>
                                </Stack>
                            </th>
                        )
                    })
                }
            </tr>
        </thead>
    )
}