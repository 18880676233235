import { useEffect, useState } from "react";
import { useGetProfessionalsQuery, useLazyGetProfessionalsQuery } from "../store/api";
import TableSearchLayout from "../components/TableSearchLayout";
import { usePagination } from "../hooks/pagination";
import Pagination from "../components/Pagination";
import { Accordion, Button, Form, InputGroup, ListGroup, Offcanvas, Stack } from "react-bootstrap";
import { DynamicTable } from "../components/DynamicTable";
import FormInput, { defaultOptionRenderer } from "../components/FormInput";
import useForm from "../hooks/form";
import { defaultBooleanSelectOptions, excludeEmptyFields, mapSSSResponseEntry } from "../util/util";
import { ArrowDownCircle, ChevronBarRight, ChevronDown, ChevronRight } from 'react-bootstrap-icons'
import useSearchSorting from "../hooks/searchSorting";
import TableHeaders from "../components/TableHeaders";

export default function Professionals() {
    const [ getProfessionals, getProfessionalsResponse ] = useLazyGetProfessionalsQuery()
    const [ getProfessionalsTotal, getProfessionalsTotalResponse ] = useLazyGetProfessionalsQuery()
    const [ showDetailsOffcanvas, setShowDetailsOffcanvas ] = useState(false)
    const toggleShowDetailsOffcanvas = () => setShowDetailsOffcanvas(!showDetailsOffcanvas)

    const { form, getInputProps, getFormField } = useForm({ formFields })

    const pagination = usePagination({
        total: getProfessionalsTotalResponse.data?.count,
    })

    const {
        page,
        setPage,
        itemsPerPage,
        setItemsPerPage,
        itemsPerPageOptions,
        numberOfPages,
        handleNextPage,
        handlePrevPage,
        prevDisabled,
        nextDisabled,
        total
    } = pagination

    const searchSorting = useSearchSorting({
        searchProp: 'name',
        searchOrder: 'ASC'
    })

    const {
        prop,
        order,
    } = searchSorting

    const getSearchData = () => {
        // get searchSort
        const header = headers.find(h => h.name === prop)

        // page number not needed for count
        const query = {
            searchRows: itemsPerPage,
            searchSort: `${order === 'ASC' ? '' : '10'}${header.id}`,
            ...excludeEmptyFields(form)
        }

        getProfessionals({
            searchType: 'Pro',
            searchPage: page,
            ...query
        })
        getProfessionalsTotal({
            searchType: 'ProCount',
            ...query
        })
    }

    const handleSearch = e => {
        e.preventDefault()

        getSearchData()
    }

    // trigger search
    useEffect(getSearchData,
        [
            page,
            itemsPerPage,
            searchSorting.prop,
            searchSorting.order
        ]
    )

    const isDataLoading = (
        getProfessionalsResponse.isUninitialized ||
        getProfessionalsResponse.isLoading ||
        getProfessionalsResponse.isFetching ||
        getProfessionalsTotalResponse.isUninitialized ||
        getProfessionalsTotalResponse.isLoading ||
        getProfessionalsTotalResponse.isFetching
    )

    const paginationElement = <Pagination
        page={ page }
        setPage={ setPage }
        itemsPerPage={ itemsPerPage }
        setItemsPerPage={ setItemsPerPage }
        itemsPerPageOptions={ itemsPerPageOptions }
        numberOfPages={ numberOfPages }
        handleNextPage={ handleNextPage }
        handlePrevPage={ handlePrevPage }
        nextDisabled={ nextDisabled }
        prevDisabled={ prevDisabled }
        total={ total }
    />

    const searchForm = (
        <Form onSubmit={ handleSearch }>
            <Stack gap={ 2 }>
                <Button type="submit" style={ { position: 'sticky', top: 0 } }>Search</Button>
                <FormInput
                    inputProps={ getInputProps('quick_search') }
                    formField={ getFormField('quick_search') }
                />
                <hr />
                {
                    formFields
                        .map(({ name, selectOptions }) => (
                            name === 'quick_search' ?
                                null :
                                <FormInput
                                    key={ name }
                                    inputProps={ getInputProps(name) }
                                    formField={ getFormField(name) }
                                    selectOptions={ selectOptions }
                                    selectOptionsRenderer={ defaultOptionRenderer }
                                />
                        ))
                }
            </Stack>
        </Form>
    )

    const TableRowRenderer = item => {
        const [ showDetails, setShowDetails ] = useState(false)
        const mappedItem = mapSSSResponseEntry(Object.values(item))
        console.log({ item, mappedItem })

        const detailsRow = (
            <tr>
                <td colSpan="5">
                    <dl>
                        <ListGroup horizontal>
                            <ListGroup.Item>
                                <dt>Location</dt>
                                <dd>{ mappedItem.work_city }, { mappedItem.work_state }</dd>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <dt>Contact</dt>
                                <dd>
                                    { mappedItem.work_phone }
                                    <br />
                                    { mappedItem.email }
                                </dd>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <dt>Fund Production</dt>
                                <dd>
                                    YTD Sales ${ mappedItem.ytd_sales }
                                    <br />
                                    Fund AUM ${ mappedItem.aum }
                                </dd>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <dt>Activity</dt>
                                <dd>
                                    Total Activity ${ mappedItem.all_activity }
                                    <br />
                                    YTD Activity ${ mappedItem.ytd_activity }
                                </dd>
                            </ListGroup.Item>
                        </ListGroup>
                    </dl>
                </td>
            </tr>
        )

        const toggleShow = () => setShowDetails(!showDetails)

        return (
            <>
                <tr key={ mappedItem.entity_id }>
                    <td className="clickable" onClick={ toggleShow }>
                        { showDetails ? <ChevronDown /> : <ChevronRight /> }
                    </td>
                    <td>{ mappedItem.first_name } { mappedItem.last_name }</td>
                    <td>{ mappedItem.firm_name }</td>
                    <td>${ Number(mappedItem.aum).toLocaleString() }</td>
                    <td>
                        <Button size="sm" onClick={ toggleShowDetailsOffcanvas }>
                            View
                        </Button>
                    </td>
                </tr>
                { showDetails ? detailsRow : null }
            </>
        )
    }

    const dataPresentation = (
        <DynamicTable
            isLoading={ isDataLoading }
            dataResponse={ getProfessionalsResponse }
            data={ getProfessionalsResponse.data }
            thead={(
                <TableHeaders
                    headers={ headers }
                    searchSorting={ searchSorting }
                />
            )}
            tableRowRenderer={ TableRowRenderer }
        />
    )

    return (
        <>
            <TableSearchLayout
                searchForm={ searchForm }
                dataPresentation={ dataPresentation }
                pagination={ paginationElement }
                title="Professionals"
            />
            <Offcanvas
                show={ showDetailsOffcanvas }
                placement="end"
                onHide={ toggleShowDetailsOffcanvas }
            >
                <Offcanvas.Body>
                    details
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

const headers = [
    { id: 0, name: '', label: null, sortable: false },
    { id: 1, name: 'name', label: 'Name' },
    { id: 2, name: 'firm', label: 'Firm Name' },
    { id: 3, name: 'aum', label: 'AUM' },
    { id: 4, name: '', label: null, sortable: false }
]

const formFields = [
    {
        name: 'quick_search',
        label: 'Quick search'
    },
    {
        name: 'first_name',
        label: 'First name'
    },
    {
        name: 'last_name',
        label: 'Last name'
    },
    {
        name: 'firm_name',
        label: 'Firm name'
    },
    {
        name: 'retirement',
        label: 'Retirement',
        defaultValue: '1',
        type: 'select',
        selectOptions: defaultBooleanSelectOptions
    },
    {
        name: 'reporting_territory',
        label: 'Reporting territory'
    },
    {
        name: 'nick_name',
        label: 'Nick name'
    },
    {
        name: 'preferred_name',
        label: 'Preferred name'
    },
    {
        name: 'work_city',
        label: 'City'
    },
    {
        name: 'work_state',
        label: 'State'
    },
    {
        name: 'work_phone',
        label: 'Phone'
    },
    {
        name: 'email',
        label: 'Email'
    },
    {
        name: 'ytd_sales',
        label: 'YTD sales'
    },
    {
        name: 'aum',
        label: 'AUM'
    },
    {
        name: 'ytd_activity',
        label: 'YTD activity'
    },
    {
        name: 'office_headcount',
        label: 'Office headcount'
    },
    {
        name: 'search_name',
        label: 'Search name'
    }
]