import { useState } from "react";
import { Container, Offcanvas } from "react-bootstrap";
import useWindowWidth from "../hooks/useWindowWidth";
import { Filter, Funnel, MenuButton } from 'react-bootstrap-icons'

export default function TableSearchLayout({
    searchForm,
    dataPresentation,
    pagination,
    additionalOptions = null,
    title="Test"
}) {
    const windowWidth = useWindowWidth()
    const [ showFilter, setShowFilter ] = useState(false)
    const toggleOffcanvas = () => setShowFilter(!showFilter)

    const useOffCanvas = windowWidth >= 800

    return (
        <div id={ `content-grid` }>
            {
                useOffCanvas ? (
                    <div id="content-sidebar">
                        { searchForm }
                    </div>
                ) : (
                    <>
                        <div id="content-sidebar-offcanvas" className="clickable" onClick={ toggleOffcanvas }>
                            <Funnel size={ 24 } />
                        </div>
                        <Offcanvas show={ showFilter } onHide={ toggleOffcanvas }>
                            <Offcanvas.Header closeButton>
                                Filter
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                { searchForm }
                            </Offcanvas.Body>
                        </Offcanvas>
                    </>
                )
            }
            <div id="content-data" className="bg-light">
                <div id="content-title" className="p-2">
                    <h1>{ title }</h1>
                </div>
                {
                    additionalOptions ?
                        <Container fluid className="mb-2">{ additionalOptions }</Container>
                        : null
                }
                <div id="content-pagination">
                    <Container fluid>
                        { pagination }
                    </Container>
                </div>
                { dataPresentation }
            </div>
        </div>
    )
}