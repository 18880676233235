export const resourceQueries = {
    vendors: {
        "entity": "ref",
        "action": "Lookup",
        "key": "refVendor"
    },
    ToA: {
        "entity": "ref",
        "action": "Lookup",
        "key": "refToa"
    },
    investmentStyle: {
        "entity": "ref",
        "action": "Lookup",
        "key": "refInvestStyle"
    },
    billingCode: {
        "entity": "ref",
        "action": "Lookup",
        "key": "refBillingCode"
    }
}