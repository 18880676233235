import { Button, Form, FormLabel, InputGroup, Stack } from "react-bootstrap";
import useWindowWidth from "../hooks/useWindowWidth";

export default function Pagination({
    page,
    setPage,
    itemsPerPage,
    itemsPerPageOptions,
    setItemsPerPage,
    numberOfPages,
    handlePrevPage,
    handleNextPage,
    prevDisabled,
    nextDisabled,
    total
}) {
    const itemsPerPageOptionsElements = itemsPerPageOptions
        .map(value => <option key={ value } value={ value }>{ value }</option>)

    const pageOptionsElements =  [ ...Array(numberOfPages) ]
        .map((_, i) => <option key={ i } value={ i + 1 }>{ i + 1 }</option>)

    const windowWidth = useWindowWidth()
    const compactView = windowWidth < 1000

    if (compactView)
        return (
            <Stack gap={ 2 }>
                <Form.Group>
                    <InputGroup size="sm">
                        <InputGroup.Text>
                            Total items: { total }
                        </InputGroup.Text>
                        <InputGroup.Text>
                            Page
                        </InputGroup.Text>
                        <Form.Select value={ page } onChange={ e => setPage(Number(e.target.value)) }>
                            { pageOptionsElements }
                        </Form.Select>
                        <InputGroup.Text>
                            of { numberOfPages }
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                <Stack direction="horizontal" gap="2">
                    <Form.Group>
                        <InputGroup size="sm">
                            <InputGroup.Text>
                                Items per page
                            </InputGroup.Text>
                            <Form.Select onChange={ e => setItemsPerPage(Number(e.target.value)) }>
                                { itemsPerPageOptionsElements }
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>
                    <div className="ms-auto"></div>
                    <Button onClick={ handlePrevPage } disabled={ prevDisabled } size="sm">Prev</Button>
                    <Button onClick={ handleNextPage } disabled={ nextDisabled } size="sm">Next</Button>
                </Stack>
            </Stack>
        )
    else
        return (
            <Stack direction="horizontal" gap="2">
                <Form.Group>
                    <InputGroup>
                        <InputGroup.Text>
                            Items per page
                        </InputGroup.Text>
                        <Form.Select onChange={ e => setItemsPerPage(Number(e.target.value)) }>
                            { itemsPerPageOptionsElements }
                        </Form.Select>
                    </InputGroup>
                </Form.Group>
                <div className="ms-auto"></div>
                <Stack direction="horizontal" gap={ 2 }>
                    <Form.Group>
                        <InputGroup>
                            <InputGroup.Text>
                                Total items: { total }
                            </InputGroup.Text>
                            <InputGroup.Text>
                                Page
                            </InputGroup.Text>
                            <Form.Select value={ page } onChange={ e => setPage(Number(e.target.value)) }>
                                { pageOptionsElements }
                            </Form.Select>
                            <InputGroup.Text>
                                of { numberOfPages }
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Button onClick={ handlePrevPage } disabled={ prevDisabled }>Prev</Button>
                    <Button onClick={ handleNextPage } disabled={ nextDisabled }>Next</Button>
                </Stack>
            </Stack>
        )
}