import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { mapSSSResponseEntry } from '../util/util'
import { resourceQueries, resourcesRequests } from './resource-requests'

export const baseUrl = 'https://test-api.v2.stadionsales.com'

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl,
        mode: 'cors',
        credentials: 'include'
    }),
    endpoints: builder => ({
        authenticate: builder.query({
            query: location => ({
                url: `/auth?relayState=${location}`
            })
        }),
        getAppReports: builder.query({
            query: query => ({
                url: `/reports/apps?${query}`
            }),
            providesTags: [ 'App-Reports' ]
        }),
        getAppReport: builder.query({
            query: key => ({
                url: `/reports/apps/${encodeURIComponent(key)}`
            })
        }),
        getVendors: builder.query({
            query: () => ({
                url: '/util/vendors',
                providesTags: [ 'Vendors' ]
            })
        }),
        getLMD: builder.query({
            query: () => ({
                url: '/util/lmd',
                providesTags: [ 'LMD' ]
            })
        }),
        getProfessionals: builder.query({
            query: queryObj => ({
                url: `/search/professionals?${new URLSearchParams(queryObj).toString()}`,
                providesTags: [ 'Professionals' ]
            })
        }),
        getStoryLinePlans: builder.query({
            query: queryObj => ({
                url: `/search/storyline-plans?${new URLSearchParams(queryObj).toString()}`,
                providesTags: [ 'SL-Plans' ]
            })
        }),
        // getResources: builder.query({
        //     query: ({ resource, queryObj }) => ({
        //         url: `/resources/${resource}?${new URLSearchParams(queryObj).toString()}`,
        //         providesTags: [ `Resource-${resource}` ],
        //     }),
        //     transformResponse: response => response.map(item => mapSSSResponseEntry(Object.values(item)))
        // }),
        getVendorResource: builder.query({
            query: () => ({
                url: `/resources/vendors?${new URLSearchParams(resourceQueries.vendors)}`
            }),
            transformResponse: response => response.map(item => mapSSSResponseEntry(Object.values(item))),
            providesTags: [ 'Resource' + resourceQueries.vendors.key ]
        }),
        getToAResource: builder.query({
            query: () => ({
                url: `/resources/ToA?${new URLSearchParams(resourceQueries.ToA)}`
            }),
            transformResponse: response => response.map(item => mapSSSResponseEntry(Object.values(item))),
            providesTags: [ 'Resource' + resourceQueries.ToA.key ]
        }),
        getInvestmentStyleResource: builder.query({
            query: () => ({
                url: `/resources/investStyle?${new URLSearchParams(resourceQueries.investmentStyle)}`
            }),
            transformResponse: response => response.map(item => mapSSSResponseEntry(Object.values(item))),
            providesTags: [ 'Resource' + resourceQueries.investmentStyle.key ]
        }),
        getBillingCodeResource: builder.query({
            query: () => ({
                url: `/resources/billingCode?${new URLSearchParams(resourceQueries.billingCode)}`
            }),
            transformResponse: response => response.map(item => mapSSSResponseEntry(Object.values(item))),
            providesTags: [ 'Resource' + resourceQueries.billingCode.key ]
        })
    })
})

export const {
    useAuthenticateQuery,
    useGetAppReportsQuery,
    useLazyGetAppReportsQuery,
    useLazyGetAppReportQuery,
    useGetVendorsQuery,
    useGetLMDQuery,
    useLazyGetProfessionalsQuery,
    useGetProfessionalsQuery,
    useLazyGetStoryLinePlansQuery,
    useGetStoryLinePlansQuery,
    useGetVendorResourceQuery,
    useGetToAResourceQuery,
    useGetInvestmentStyleResourceQuery,
    useGetBillingCodeResourceQuery
    // useLazyGetResourcesQuery,
    // useGetResourcesQuery
} = api