import { Alert, Button, Container, ListGroup, Navbar, Offcanvas, Spinner } from 'react-bootstrap'
import { Link, Outlet, Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from 'react-router-dom'
import logo from '../media/stadionLogoIcon3.png'
import { useEffect, useState } from 'react'
import routes from '../routes'
import ReportsBrowser from '../pages/ReportsBrowser'
import { baseUrl, useAuthenticateQuery } from '../store/api'
import { List } from 'react-bootstrap-icons'
import Professionals from '../pages/Professionals'
import StoryLinePlans from '../pages/StoryLinePlans'

export default function Layout() {
    const authResponse = useAuthenticateQuery()
    const [ showMenu, setShowMenu ] = useState(false)

    const handleShow = () => setShowMenu(true)
    const handleHide = () => setShowMenu(false)

    useEffect(() => {
        if (authResponse.isError)
            window.location = `${baseUrl}/auth?relayState=${window.location.href}`
    }, [ authResponse ])

    if (authResponse.isUninitialized || authResponse.isLoading || authResponse.isFetching)
        return <Spinner />

    if (authResponse.isError)
        return <Alert variant='info'>Redirecting to login page...</Alert>

    return (
        <div id="layout-grid">
            <Router>
                <Navbar expand="lg" fixed="sticky" className="bg-dark">
                    <Container fluid>
                        <Navbar.Brand className='text-white'>
                            <img
                                alt=""
                                src={ logo }
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                            />{' '}
                            StadionSales
                        </Navbar.Brand>
                        <Button variant='outline-primary' onClick={ handleShow }>
                            <List />
                            
                        </Button>
                    </Container>
                </Navbar>
                <Offcanvas show={ showMenu } onHide={ handleHide } placement='end'>
                    <NavLinks />
                </Offcanvas>
                <Routes>
                    <Route path='/reports' element={ <ReportsBrowser />} >
                    </Route>
                    <Route path='/search/professionals' element={ <Professionals />} >
                    </Route>
                    <Route path='/search/storyline_plans' element={ <StoryLinePlans />} >
                    </Route>
                </Routes>
                <Outlet />
            </Router>
        </div>
    );
}

function NavLinks() {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <ListGroup variant="flush">
            {
                routes.map(route => <ListGroup.Item
                    key={ route.pathname }
                    action
                    onClick={ () => navigate(route.pathname) }
                    active={ location.pathname === route.pathname }
                >
                    { route.title }
                </ListGroup.Item>
                )
            }
        </ListGroup>
    )
}